@font-face {
  font-family: 'Simplon BP';
  src: url('../fonts/SimplonBP-Bold.otf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Simplon BP';
  src: url('../fonts/SimplonBP-BoldItalic.otf');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Simplon BP';
  src: url('../fonts/SimplonBP-Light.otf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Simplon BP';
  src: url('../fonts/SimplonBP-LightItalic.otf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Simplon BP';
  src: url('../fonts/SimplonBP-Medium.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Simplon BP';
  src: url('../fonts/SimplonBP-MediumItalic.otf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Simplon BP';
  src: url('../fonts/SimplonBP-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Simplon BP';
  src: url('../fonts/SimplonBP-RegularItalic.otf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Simplon Oi';
  src: url('../fonts/SimplonOi-Headline.otf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'din';
  src: url('../fonts/din/DINBold.ttf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'din';
  src: url('../fonts/din/DINLight.ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'din';
  src: url('../fonts/din/DINMedium.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'din';
  src: url('../fonts/din/DINRegular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'din-alternate';
  src: url('../fonts/din/DINRegularAlternate.ttf');
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Simplon BP', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #5b5b5b;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Simplon Oi', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  color: #5b5b5b;
}

a,
span,
p,
input,
select,
label {
  font-family: 'Simplon BP', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  color: #5b5b5b;
}

button {
  font-family: 'Simplon Oi', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  color: #5b5b5b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.block-ui {
  display: initial;
}

.block-ui-container {
  position: fixed !important;
  z-index: 1055;
}

.block-ui-message-container {
  left: 50% !important;
}

@media print {
  body {
    width: 60%;
    margin: 0px;
    padding: 0px;
  }
}

.pagination > li > a {
  z-index: unset !important;
}

.redux-toastr {
  z-index: 9999999999999999;
}
.redux-toastr .top-right {
  z-index: 999999999999999999;
}

.close-toastr {
  opacity: 1 !important;
}
.close-toastr > span {
  font-family: 'Simplon BP' !important;
  font-weight: bold !important;
  font-size: 18px;
  color: black;
  display: flex;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 50%;
  line-height: normal;
  top: 19px !important;
}
