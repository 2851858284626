@font-face {
  font-family: 'Simplon BP';
  src: url(/static/media/SimplonBP-Bold.7986f57d.otf);
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Simplon BP';
  src: url(/static/media/SimplonBP-BoldItalic.d024a726.otf);
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Simplon BP';
  src: url(/static/media/SimplonBP-Light.0fc2d630.otf);
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Simplon BP';
  src: url(/static/media/SimplonBP-LightItalic.c273f64f.otf);
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Simplon BP';
  src: url(/static/media/SimplonBP-Medium.3457a8eb.otf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Simplon BP';
  src: url(/static/media/SimplonBP-MediumItalic.52a0a25f.otf);
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Simplon BP';
  src: url(/static/media/SimplonBP-Regular.90dff158.otf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Simplon BP';
  src: url(/static/media/SimplonBP-RegularItalic.50e782e8.otf);
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Simplon Oi';
  src: url(/static/media/SimplonOi-Headline.f0e22738.otf);
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'din';
  src: url(/static/media/DINBold.e0771241.ttf);
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'din';
  src: url(/static/media/DINLight.9c18ddb8.ttf);
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'din';
  src: url(/static/media/DINMedium.902d51bb.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'din';
  src: url(/static/media/DINRegular.e0185b8d.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'din-alternate';
  src: url(/static/media/DINRegularAlternate.c7a6d84f.ttf);
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Simplon BP', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #5b5b5b;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Simplon Oi', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  color: #5b5b5b;
}

a,
span,
p,
input,
select,
label {
  font-family: 'Simplon BP', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  color: #5b5b5b;
}

button {
  font-family: 'Simplon Oi', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  color: #5b5b5b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.block-ui {
  display: initial;
}

.block-ui-container {
  position: fixed !important;
  z-index: 1055;
}

.block-ui-message-container {
  left: 50% !important;
}

@media print {
  body {
    width: 60%;
    margin: 0px;
    padding: 0px;
  }
}

.pagination > li > a {
  z-index: unset !important;
}

.redux-toastr {
  z-index: 9999999999999999;
}
.redux-toastr .top-right {
  z-index: 999999999999999999;
}

.close-toastr {
  opacity: 1 !important;
}
.close-toastr > span {
  font-family: 'Simplon BP' !important;
  font-weight: bold !important;
  font-size: 18px;
  color: black;
  display: flex;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 50%;
  line-height: normal;
  top: 19px !important;
}

body {
  zoom: 80%;
}

.btn-margin {
  margin: 7px 3px;
}

label.required::before {
  color: red;
  content: '*';
  display: "block";
  margin-right: 8;
}

.sortable-item-move .media-item-add {
  display: none;
}

.sortable-item-move {
  max-height: 50px !important;
}

[class*='ButtonWrap'] {
  z-index:0 !important;
}
.DraftEditor-editorContainer {
  z-index:0 !important;
}

.sr-only {
  display: none;
}

html {
  height: calc(100% - 64px) !important;
}
body {
  height: 100% !important;
}
#root {
  height: 100% !important;
}

.sr-only {
  display: none;
}

html {
  height: calc(100% - 64px) !important;
}
body {
  height: 100% !important;
}
#root {
  height: 100% !important;
}

