body {
  zoom: 80%;
}

.btn-margin {
  margin: 7px 3px;
}

label.required::before {
  color: red;
  content: '*';
  display: "block";
  margin-right: 8;
}

.sortable-item-move .media-item-add {
  display: none;
}

.sortable-item-move {
  max-height: 50px !important;
}

[class*='ButtonWrap'] {
  z-index:0 !important;
}
.DraftEditor-editorContainer {
  z-index:0 !important;
}
